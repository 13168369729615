<template>
    <div class="flex justify-between items-start">
        <div class="w-full mr-2">
            <div class="mt-2 grid  grid-cols-4 gap-4">
                <div>
                    <div
                        v-if="!editable"
                        class="flex flex-col items-start justify-start gap-2"
                    >
                        <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.name') }}</label>

                        <div class="block truncate w-140p">{{ item.name }}</div>
                    </div>

                    <FormInput
                        v-else
                        :label="$t('task.fields.name')"
                        inputName="name"
                        :value="item.name"
                        :disabled="!editable"
                        :errorMessage="formErrors['contacts.' + index + '.name']"
                        @handleInputChange="handleNameChange"
                    />
                </div>

                <div>
                    <div
                        v-if="!editable"
                        class="flex flex-col items-start justify-start gap-2"
                    >
                        <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.email') }}</label>

                        <div class="block truncate w-140p">{{ item.email }}</div>
                    </div>

                    <FormInput
                        v-else
                        :label="$t('task.fields.email')"
                        inputName="email"
                        :value="item.email"
                        :disabled="!editable"
                        :errorMessage="formErrors['contacts.' + index + '.email']"
                        @handleInputChange="handleEmailChange"
                    />
                </div>

                <div>
                    <div
                        v-if="!editable"
                        class="flex flex-col items-start justify-start gap-2"
                    >
                        <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.phone') }}</label>

                        <div>{{ item.phone }}</div>
                    </div>

                    <FormInput
                        v-else
                        :label="$t('task.fields.phone')"
                        inputName="phone"
                        :value="item.phone"
                        :disabled="!editable"
                        :errorMessage="formErrors['contacts.' + index + '.phone']"
                        @handleInputChange="handlePhoneChange"
                    />
                </div>

                <div>
                    <div
                        v-if="!editable"
                        class="flex flex-col items-start justify-start gap-2"
                    >
                        <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.role') }}</label>

                        <div class="block truncate w-140p">{{ item.role }}</div>
                    </div>

                    <FormInput
                        v-else
                        :label="$t('task.fields.role')"
                        inputName="role"
                        :value="item.role"
                        :disabled="!editable"
                        :errorMessage="formErrors['contacts.' + index + '.role']"
                        @handleInputChange="handleRoleChange"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="editable"
            v-click-outside="closeDropdown"
            class="relative flex justify-end items-center"
        >
            <div
                class="mt-12 cursor-pointer"
                @click="toggleDropdown"
            >
                <DropDownThreeDots />
            </div>

            <div
                v-if="isDropdownOpen"
                class="mx-3 origin-top-right absolute top-6 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="project-options-menu-0"
            >
                <div class="py-1" role="none">
                    <a
                        @click.prevent="$emit('delete'); isDropdownOpen = false"
                        href="#"
                        class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                    >
                        {{ $t('global.buttons.delete') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';
import ClickOutside from 'vue-click-outside';
import FormInput from '@/components/Main/Form/FormInput/FormInput';

export default {
    name: 'Item',
    directives: {
        ClickOutside
    },
    components: { FormInput, DropDownThreeDots },
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        editable: {
            type: Boolean,
            required: true
        },
        formErrors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDropdownOpen: false
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        handleNameChange(inputName, value) {
            this.item.name = value;
            this.$emit('change', this.item);

            delete this.formErrors['contacts.' + this.index + '.name'];
        },
        handleEmailChange(inputName, value) {
            this.item.email = value;
            this.$emit('change', this.item);

            delete this.formErrors['contacts.' + this.index + '.email'];
        },
        handlePhoneChange(inputName, value) {
            this.item.phone = value;
            this.$emit('change', this.item);

            delete this.formErrors['contacts.' + this.index + '.phone'];
        },
        handleRoleChange(inputName, value) {
            this.item.role = value;
            this.$emit('change', this.item);

            delete this.formErrors['contacts.' + this.index + '.role'];
        }
    }
};
</script>

<style scoped>

</style>
