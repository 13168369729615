<template>
    <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-3 border-t border-b">
        <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-2">
                    <FormInput
                        :label="$t('task.fields.title')"
                        inputName="title"
                        :value="form.title"
                        :errorMessage="formErrors.title"
                        @handleInputChange="handleInputChange"
                    />
                </div>

                <div class="sm:col-span-2">
                    <FormTextAreaEditor
                        :label="$t('customer.fields.description')"
                        :value="form.description"
                        inputName="description"
                        :disabled="!editable"
                        @handleInputChange="handleInputChange"
                    />
                </div>

                <div class="sm:col-span-2">
                    <div class="pt-5 border-t">
                        <h2 class="text-lg font-medium text-gray-900">{{ $t('task.fields.contacts') }}</h2>
                    </div>

                    <TaskContacts
                        v-model="form.contacts"
                        :editable="editable"
                        :formErrors="formErrors"
                    />
                </div>

                <div class="sm:col-span-2">
                    <CheckListsSection
                        v-if="pageUpdateAction"
                        :checkLists="form.checklists"
                        @handleDeleteCheckListItem="handleDeleteCheckListItem"
                        @handleDeleteCheckList="handleDeleteCheckList"
                        @handleCreateNewCheckListItem="handleCreateNewCheckListItem"
                        @handleUpdateCheckList="handleUpdateCheckList"
                        @handleUpdateCheckListItem="handleUpdateCheckListItem"
                        @handleEditCheckListItemStatus="handleEditCheckListItemStatus"
                    />
                </div>
                <div class="sm:col-span-2 border-t">
                    <CommentBox
                        v-if="pageUpdateAction"
                        :accountAvatar="account.avatar"
                        :authorName="account.name"
                        :ownerId="form.id"
                        :comments="form.comments"
                        :prefix="prefix"
                    />
                </div>
            </div>
        </div>
        <aside class="xl:pl-8">
            <RightAside
                :form="form"
                :pageUpdateAction="pageUpdateAction"
                @handleInputFieldChange="handleInputFieldChange"
                @handleAddAttachments="handleAddAttachments"
                @deleteAttachmentFile="deleteAttachmentFile"
                @handleAddNewCheckList="handleAddNewCheckList"
                @handleAddNewLink="addNewLink"
                @handleDeleteLink="deleteNewLink"
                @handleEditLink="editNewLink"
                @handleChangeAssigneeMembers="handleChangeAssigneeMembers"
                @handleAddGoogleAttachments="handleAddGoogleAttachments"
                @deleteGoogleAttachmentFile="deleteGoogleAttachmentFile"
                @handleChangeAssigneeLabels="handleChangeAssigneeLabels"
            />
        </aside>
    </div>
</template>

<script>

import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import { mapActions, mapGetters } from 'vuex';
import RightAside from '@/components/Task/RightAside/RightAside';
import { EventBus } from '@/eventBus/event-bus';
import { Attachments } from '@/mixins/Attachments';
import { RequestTaskForm } from '@/mixins/Task/RequestTaskForm';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import CheckListsSection from '@/components/CheckList/CheckListsSection/CheckLists/CheckLists';
import { withTaskCheckList } from '@/mixins/Task/withTaskCheckList';
import FormTextAreaEditor from '../../../components/Main/Form/FormTextArea/FormTextAreaEditor';
import TaskContacts from '@/components/TaskContacts/TaskContacts';

export default {
    name: 'CreateOrUpdate',
    mixins: [Attachments, RequestTaskForm, withTaskCheckList],
    components: {
        TaskContacts,
        FormTextAreaEditor,
        CheckListsSection,
        CommentBox,
        RightAside,
        FormTextArea,
        MainFieldTitle,
        FormInput
    },
    data() {
        return {
            loading: true,
            editable: true,
            prefix: 'task',
            pageUpdateAction: false,
            form: {
                id: '',
                title: '',
                description: '',
                apartments: [],
                listing_id: '',
                customer_id: '',
                member_id: '',
                contacts: [],
                assigneeId: '',
                checklists: [],
                due: '',
                files: [],
                google_files: [],
                members: [],
                comments: [],
                listings: [],
                createdAt: '',
                invoices: [],
                labels: [],
                links: []
            },
            formErrors: {}
        };
    },
    computed: {
        ...mapGetters([
            'account',
            'getListings',
            'updateTaskSuccess',
            'taskResponseMessage',
            'getAllListings',
            'labels'
        ])
    },
    watch: {
        getAllListings(val) {
            this.form.listings = val;
        },
        getListings(newValue) {
            this.form.listings = newValue;
            const { taskId } = this.$route.params;
            newValue.length && !taskId ? this.loading = false : true;
        },
        updateTaskSuccess(newValue) {
            newValue && this.redirectToShowPage();
        },
        task(data) {
            if (this.pageUpdateAction) {
                this.form = { ...data };
                this.handleSetBreadCrumbItem(data.title);
                this.loading = false;
            }
        }
    },
    mounted() {
        this.handleSetBreadCrumbItems({ index: 0, to: '/boards' });
        this.handleSetBreadCrumbItems({ index: 1, to: '' });
        const { listingId } = this.$route.query;
        if (listingId !== undefined) {
            this.form.listing_id = String(listingId);
            const { taskId, id } = this.$route.params;
            id && this.handleGetBoard(id);
            if (taskId) {
                this.loading = true;
                this.pageUpdateAction = true;
                this.handleGetTask(taskId);
            }
        } else {
            this.handleFetchAllListings();
            this.loading = false;
            const user = {
                name: this.account.name,
                id: this.account.id,
                avatar: this.account.avatar
            };
            this.form.members.push(user);
        }
        EventBus.$on('handleSaveForm', () => this.handleSubmit());

    },
    methods: {
        ...mapActions({
            handleGetBoard: 'fetchBoard',
            handleAttachMember: 'attachMember',
            handleDetachMember: 'detachMember',
            handleGetTask: 'fetchTask',
            handleSetBreadCrumbItem: 'setBreadCrumbItem',
            handleFetchAllListings: 'fetchAllListings',
            getLabels: 'getLabels',
            handleAttachLabel: 'fetchAttachLabel',
            handleDetachLabel: 'fetchDetachLabel',
            handleSetBreadCrumbItems: 'setBreadCrumbItems',
            handleResetBreadCrumbItems: 'resetBreadCrumbItems'
        }),
        handleChangeAssigneeMembers({ user, isAssigned }) {
            const data = { id: this.form.id, form: { member_id: user.id } };
            !isAssigned ? this.attachMember(data, user) : this.detachMember(data, user.id);
        },
        handleChangeAssigneeLabels({ label, isAssigned }) {
            const data = { id: this.form.id, form: { label_id: label.id } };
            !isAssigned ? this.attachLabel(data, label) : this.detachLabel(data, label.id);
        },
        attachMember(data, user) {
            this.form.members.unshift(user);
            this.pageUpdateAction && this.handleAttachMember({ ...data });
        },
        attachLabel(data, label) {
            this.form.labels.unshift(label);
            this.pageUpdateAction && this.handleAttachLabel({ ...data });
        },
        detachMember(data, userId) {
            this.form.members = this.form.members.filter(member => member.id !== userId);
            this.pageUpdateAction && this.handleDetachMember({ ...data });
        },
        detachLabel(data, labelId) {
            this.form.labels = this.form.labels.filter(label => label.id !== labelId);
            this.pageUpdateAction && this.handleDetachLabel({ ...data });
        },
        addNewLink(event) {
            this.form.links.push({
                id: Math.round(Math.random() * 1000000),
                url: event.url,
                name: event.name
            });
        },
        editNewLink(event) {
            this.form.links.find(link => link.id === event.id).url = event.url;
        },
        deleteNewLink(event) {
            this.form.links = this.form.links.filter(link => link.id !== event);
        },
        handleSubmit() {
            this.pageUpdateAction ? this.updateTask() : this.createTask();
        }
    },
    destroyed() {
      this.handleResetBreadCrumbItems();
        EventBus.$off('handleSaveForm');
        this.$destroy();
    }
};
</script>

<style scoped>

</style>
